import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/dist/client/router';
import { useEffect } from 'react';
import useUser from '~/components/SignInContainer/queries/user/useUser';
import * as Sentry from '@sentry/nextjs';
import useUserIsAdvisorCustomerService from './hooks/useUserIsAdvisorCustomerService';
import SignIn from './SignIn';
import SignInAdvisorOverrideSelector from './SignInAdvisorOverrideSelector';
import Toast from '../Toast';

export type SignInFormData = {
  username: string;
  password: string;
};

type CustomError = {
  response: {
    status: number;
    data: {
      detail: string;
    };
  };
};

const SignInContainer = () => {
  const router = useRouter();
  const { isLoading, isAuthenticated, logout } = useAuth0();
  const isAdvisorUserCostumerService = useUserIsAdvisorCustomerService();
  const {
    isSuccess,
    isLoading: isLoadingUser,
    data,
    error,
    isError,
  } = useUser();
  const errorToast = Toast({ status: 'error', duration: 10000 });

  Sentry.setUser({ email: data?.email });

  useEffect(() => {
    if (isError) {
      const { response } = error as unknown as CustomError;
      if (
        response?.status === 403 &&
        response?.data?.detail.includes('Cannot find user')
      ) {
        errorToast({
          title:
            'There is an error occurring with the permissions on this account',
          description:
            'Please contact the Investor Relations team for assistance in resolving this issue.',
        });

        setTimeout(() => {
          logout({ returnTo: window.location.origin });
        }, 5000);
      }
    }
  }, [isError, error, errorToast, logout]);

  useEffect(() => {
    if (!isSuccess) return;

    if (!isLoading && isAuthenticated && !isAdvisorUserCostumerService)
      router.push('/qualified-purchasers/dashboard');

    if (!isLoading && !isAuthenticated && window !== undefined)
      window.localStorage.clear();
  }, [isLoading, isAuthenticated, isSuccess, isAdvisorUserCostumerService]);

  const renderContainerComponent = () => {
    if (!isAuthenticated) return <SignIn />;

    if (isLoadingUser || isError)
      return <CircularProgress isIndeterminate color="brand.perwinkle.500" />;

    if (isAuthenticated && isAdvisorUserCostumerService)
      return <SignInAdvisorOverrideSelector />;

    return null;
  };

  return (
    <Flex
      h="md"
      w="md"
      py="4"
      px="16"
      justify="center"
      align="center"
      direction="column">
      {renderContainerComponent()}
    </Flex>
  );
};

export default SignInContainer;
